<template>
  <card class="mx-auto my-20">
    <template #title>Create an account</template>
    <template>
      <o-auth />

      <hr class="" />

      <div class="mx-2 flex flex-col gap-2 justify-center">
        <form-input
          type="text"
          placeholder="First Name"
          label="First Name"
          v-model="firstName"
        />
        <form-input
          type="text"
          placeholder="Last Name"
          label="Last Name"
          v-model="lastName"
        />
        <form-input
          type="email"
          placeholder="Email"
          label="Email"
          v-model="email"
        />
        <form-input
          type="password"
          placeholder="Password"
          label="Password"
          v-model="password"
        />
        <form-input
          type="password"
          placeholder="Repeat Password"
          label="Repeat Password"
          v-model="password2"
        />
        <div v-if="errors">
          <p
            v-for="(name, value, key) in errors"
            :key="key"
            class="text-red-500 text-xs"
          >
            {{ value }}: {{ name }}
          </p>
        </div>
        <div class="flex">
          <label class="flex items-center">
            <input type="checkbox" v-model="tosAccepted" />
            <span class="text-xs text-gray-900 ml-1">
              By creating an account i agree with ToS
            </span>
          </label>
        </div>
      </div>
      <div class="mx-16 mt-6">
        <t-button
          primary
          :loading="isLoading"
          class="mx-auto w-28 h-7"
          @click.native="trySingUp()"
        >
          Signup
        </t-button>
      </div>
      <div class="mx-auto">
        <t-button link tag="router-link" class="mx-auto" to="/signin">
          Login
        </t-button>
      </div>
    </template>
  </card>
</template>

<script>
import Card from "@/components/Card.vue";
import TButton from "@/components/core/Button.vue";
import FormInput from "@/components/core/FormInput.vue";
import OAuth from "./oauth2/Index.vue";
import { mapGetters } from "vuex";

export default {
  name: "Signup",
  components: {
    Card,
    TButton,
    FormInput,
    OAuth,
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      password2: "",
      tosAccepted: false,
    };
  },
  methods: {
    async trySingUp() {
      if (this.fieldsNotEmpty) {
        await this.$store.dispatch("auth/createUser", {
          email: this.email,
          password: this.password,
          password2: this.password2,
          first_name: this.firstName,
          last_name: this.lastName,
        });

        await this.goHome();
      } else {
        const field =
          !this.email.length > 0
            ? "Email"
            : !this.password.length > 0
            ? "Password"
            : !this.password2.length > 0
            ? "Repeat Password"
            : "";
        await this.$store.commit("auth/SET_ERRORS", {
          error: `All fields are required`,
        });
      }
    },
    goHome() {
      if (!this.errors) {
        this.$router.push("/");
      }
    },
  },
  computed: {
    ...mapGetters({
      errors: "auth/errors",
      isLoading: "auth/isLoading",
    }),

    fieldsNotEmpty() {
      return (
        this.email.length > 0 &&
        this.password.length > 0 &&
        this.password2.length > 0 &&
        this.firstName.length > 0 &&
        this.lastName.length > 0 &&
        this.tosAccepted == true
      );
    },
  },
  beforeDestroy() {
    this.$store.commit("auth/SET_ERRORS", null);
  },
};
</script>
